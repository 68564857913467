<template>
  <article id="introduction">
    <docs-nav />
    <header>
      <h2>Introduction</h2>
    </header>
    <section>
      <p>Conlang Workbench is a tool designed to facilitate the creation of "naturalistic" conlangs, whose lexicons and phonologies are derived through linguistic change, or evolution.</p>
      <p>The workbench uses a custom definition language to allow you to describe your conlang and the changes it goes through. This documentation serves as a reference for this coding language, as well as for the tools that allow you to interact with the generated conlang.</p>
      <p>This guide will be updated regularly to include documentation on the latest features and advice on how to use them, as well as common errors and how to solve them.</p>
    </section>
    <section>
      <h3>Getting Started</h3>
      <p>If you're new to Conlang Workbench, check out the <router-link to="/docs/getting-started">Getting Started</router-link> guide for a rundown of the workbench's key features and some basic examples.</p>
    </section>
  </article>
</template>

<script>
import DocsNav from '@/components/DocsNav.vue'

export default {
  name: 'Introduction',
  components: { DocsNav },
}
</script>
